import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { View } from 'react-primitives'

import { Chip } from 'src/components/Chip/Chip'
import { styled } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { H5 } from 'src/retired/shared/Typography'
import Touchable from 'src/retired/shared/Touchable'
import Icon from 'src/retired/shared/Icon'
import truthy from 'src/utils/truthy'
import { useLocation, useHistory } from 'src/navigation'

import { TabType, addSearchDataToUrl, SearchParams, useSearchParams } from '../utils'

import { Tab } from './Tab'

const TabsContainer = styled.View(() => ({
  paddingTop: 24,
  width: '100%',
  flexDirection: 'row',
}))

export const SidebarContainer = ({
  children,
  onClearAllClick,
  onSubmitAllClick,
  areFiltersTouched,
  close,
}: {
  children: JSX.Element
  onClearAllClick: () => void
  onSubmitAllClick: () => void
  areFiltersTouched?: boolean
  close?: () => void
}) => {
  const { t } = useTranslation('searchScreen')
  const { metrics, colors } = useDeedTheme()
  const history = useHistory()
  const { search } = useLocation()
  const { activeTab } = useSearchParams()
  const user = useSelector(selectCurrentUser)

  const [tab, setTab] = useState(activeTab)
  const enabledTabs = [
    // @NOTE-CH: we'd need to check user?.hasFeature('donations') before displaying this tab
    TabType.donate,
    user?.hasFeature('volunteering') && TabType.volunteer,
    // TabType.event
  ].filter(truthy)

  const addTabNameToUrl = (tabName: TabType) => {
    addSearchDataToUrl(
      {
        [SearchParams.tab]: tabName,
        // @NOTE-CH: Hacky way of cleaning the `location` parameter on every tab change.
        [SearchParams.location]: undefined,
      },
      search,
      history
    )
  }

  return (
    <View
      style={{
        backgroundColor: colors.white,
        height: metrics.isLarge ? '95vh' : '95%',
        width: metrics.isLarge ? 325 : '100%',
        borderRadius: 16,
        position: metrics.isLarge && 'sticky',
        top: metrics.isLarge ? 10 : 24,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: 20,
          paddingTop: 20,
        }}
      >
        <H5>{t`filters`}</H5>
        <View style={{ flexDirection: 'row' }}>
          <Chip
            label={t`clearAll`}
            variant="outlined"
            color="primary"
            onClick={onClearAllClick}
            style={{ marginRight: 12 }}
          />
          <Chip
            label={t`apply`}
            variant="filled"
            color="primary"
            onClick={() => {
              onSubmitAllClick()
              if ((metrics.isSmall || metrics.isMedium) && close) {
                close()
              }
            }}
            disabled={!areFiltersTouched}
          />
          {close && (
            <Touchable
              onPress={close}
              style={{
                paddingLeft: 12,
                width: 30,
                height: 30,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon icon="closeThick" width={16} />
            </Touchable>
          )}
        </View>
      </View>

      <TabsContainer>
        {enabledTabs.map((tabType) => (
          <Tab
            key={tabType}
            tabType={tabType}
            onPress={() => {
              setTab(TabType[tabType])
              addTabNameToUrl(TabType[tabType])
            }}
            isActive={tab === TabType[tabType]}
            tabSize={enabledTabs.length}
          />
        ))}
      </TabsContainer>

      {children}
    </View>
  )
}
