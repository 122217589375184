import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import config from 'src/config'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { Divider } from 'src/retired/elements'

import { VolunteerRewardsTableContainer } from './VolunteerRewards/VolunteerRewardsTableContainer'
import { VolunteerTimesTableContainer } from './VolunteerTimes/VolunteerTimesTableContainer'

const TabPanel = ({ children, value, index }: { children?: React.ReactNode; index: number; value: number }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`volunteer-tabpanel-${index}`}
    aria-labelledby={`volunteer-tab-${index}`}
  >
    {value === index && <Box>{children}</Box>}
  </div>
)

function a11yProps(index: number) {
  return {
    id: `volunteer-tab-${index}`,
    'aria-controls': `volunteer-tabpanel-${index}`,
  }
}

export const VolunteerTabs = () => {
  const { isProduction } = config
  const [value, setValue] = useState(0)
  const { t } = useTranslation('volunteerTimesProfile')
  const user = useSelector(selectCurrentUser)

  const isVolunteerRewardEnabled = user?.hasFeature('volunteerReward')
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  if (!isVolunteerRewardEnabled || isProduction) {
    return (
      <>
        <Divider style={{ marginBottom: 0 }} />
        <VolunteerTimesTableContainer />
      </>
    )
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ px: 3 }}>
        <Tabs value={value} onChange={handleChange} aria-label="volunteer-tabs">
          <Tab label={t`volunteerHours`} {...a11yProps(0)} />
          <Tab label={t`volunteerGrants`} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Divider style={{ marginBottom: 0 }} />
      <TabPanel value={value} index={0}>
        <VolunteerTimesTableContainer />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <VolunteerRewardsTableContainer />
      </TabPanel>
    </Box>
  )
}
