import { createSelector } from 'reselect'
import { Set } from 'immutable'
import { format, isWeekend, startOfDay, endOfDay } from 'date-fns'
import isPointWithinRadius from 'geolib/es/isPointWithinRadius'

import User from 'src/entities/user/model'
import Deed from 'src/entities/deed/model'
import { selectUpcomingDeeds, selectUpcomingDeedsWithLocationFiltering } from 'src/entities/deed/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { fuseSearchDeeds } from 'src/containers/modules/Search/selectors'
import { DeedMap } from 'src/entities/deed/reducer'
import { Coordinates } from 'src/utils/coordinates'
import { State } from 'src/reducers'

import { shouldFilterDayOrTime } from './utils'
import { initialState } from './reducer'

export type FeedState = typeof initialState

const selectFeedState = (state: State) => state.get('feed') || initialState

export const selectLoading = createSelector(selectFeedState, (state) => state.get('loading'))
export const selectError = createSelector(selectFeedState, (state) => state.get('error'))

export const selectDeedTypeFeedLoaded = createSelector(
  selectFeedState,
  (_state: FeedState, deedType: string | undefined) => deedType,
  (state, deedType) => state.get(deedType)?.get?.('loaded')
)

export const selectDeedTypeFeedLoading = createSelector(
  selectFeedState,
  (_state: FeedState, deedType: string | undefined) => deedType,
  (state, deedType) => state.get(deedType)?.get?.('loading')
)

export const selectDeedTypeFeedCurrentPage = createSelector(
  selectFeedState,
  (_state: FeedState, deedType: string | undefined) => deedType,
  (state, deedType) => state.get(deedType)?.get?.('page')
)

export const selectDeedTypeFeedMoreResults = createSelector(
  selectFeedState,
  (_state: FeedState, deedType: string | undefined) => deedType,
  (state, deedType) => state.get(deedType)?.get?.('moreResults')
)

export const selectScrollPositions = createSelector(selectFeedState, (state) => state.get('scrollPositions'))

export const selectCampaignDeeds = createSelector(
  selectUpcomingDeedsWithLocationFiltering,
  selectCurrentUser,
  (state, deedType) => deedType,
  (deeds, user, deedType) => {
    const isEmployee = user?.isEmployee()

    if (deedType === 'Campaign' && isEmployee) {
      // if user is an employee move company fundraisers to the top and keep original sorting by date
      return deeds.sort((a, b) => {
        const prioA = priorityScoreByPartnerAndOptedIn(a, user)
        const prioB = priorityScoreByPartnerAndOptedIn(b, user)
        const prioritizeA = prioA > prioB
        const prioritizeB = prioB > prioA

        if (prioA === prioB) {
          return a.startingAt > b.startingAt ? -1 : 1
        }

        if (prioritizeB) {
          return 1
        }

        if (prioritizeA) {
          return -1
        }
        return 0
      })
    }
    return deeds
  }
)

const priorityScoreByPartnerAndOptedIn = (deed, user) =>
  (deed.optedIn ? 0.5 : 0) + (deed.partner?.id === user.organization.id ? 1 : 0)

const filterDeeds = (deeds: DeedMap, user?: User, filters?: any, deedType?: string, location?: any) => {
  const isEmployee = user?.isEmployee()

  if (filters) {
    if (isEmployee && deedType !== 'Campaign' && filters.get('corporateOnly')) {
      // eslint-disable-next-line no-param-reassign
      deeds = deeds.filter((deed) => (deed.partner ? deed.optedIn || deed.partner.id === user?.organization.id : false))
    }
    // eslint-disable-next-line no-param-reassign
    deeds = deeds.filter((deed) => applyDeedFilters(deed, filters, location))

    const searchTerm = filters.get('searchTerm')
    if (searchTerm && ['Project', 'Event'].includes(deedType!)) {
      // eslint-disable-next-line no-param-reassign
      deeds = fuseSearchDeeds(deeds, searchTerm)
    }
  }

  return deeds
}

// @NOTE-CH: This selector is here for backward compatibility with the old Feed screen that is still in use for BaseEvents
export const selectFilteredDeedsWithLocation = createSelector(
  selectUpcomingDeedsWithLocationFiltering,
  selectCurrentUser,
  (state) => state.get('feedFilter'),
  (_state, deedType) => deedType,
  (_state, _deedType, location) => location,
  filterDeeds
)

export const selectFilteredDeeds = createSelector(
  selectUpcomingDeeds,
  selectCurrentUser,
  (state) => state.get('feedFilter'),
  (_state, deedType) => deedType,
  (_state, _deedType, location) => location,
  filterDeeds
)

const applyDeedFilters = (deed: Deed, filters: any, location: any) => {
  const eventTypes = ['Event', 'BaseEvent']
  // Filter out external deeds coming from search results
  if (deed.matchForSearchTerm) {
    return false
  }

  let shouldRemove = false

  if (eventTypes.includes(deed.type)) {
    const selectedDaysAndTimes = filters.get('selectedDaysAndTimes')

    if (selectedDaysAndTimes.size > 0) {
      // Weekdays/Weekends filters are not used for the new search result page
      // When we drop the /volunteer/events page we can remove this if-statement
      const allowWeekdays = selectedDaysAndTimes.has('Weekdays')
      const allowWeekends = selectedDaysAndTimes.has('Weekends')
      if (allowWeekdays || allowWeekends) {
        const isStartingDateAWeekend = isWeekend(deed.startingAt)
        if (!((allowWeekdays && !isStartingDateAWeekend) || (allowWeekends && isStartingDateAWeekend))) {
          shouldRemove = true
        }
      }

      const selectedDaysAndTimesAsJS = selectedDaysAndTimes?.toJS?.() ?? []
      if (!shouldFilterDayOrTime(deed.startingAt, deed.timeZone, selectedDaysAndTimesAsJS)) {
        shouldRemove = true
      }
    }

    const selectedDate = filters.get('selectedDate')

    if (selectedDate) {
      if (deed.startingAt && deed.endingAt) {
        const roundedStartingAt = startOfDay(new Date(deed.startingAt))
        const roundedEndingAt = endOfDay(new Date(deed.endingAt))
        const selectedDateObj = startOfDay(new Date(selectedDate))

        if (selectedDateObj < roundedStartingAt || selectedDateObj > roundedEndingAt) {
          shouldRemove = true
        }
      } else if (deed.startingAt) {
        if (format(deed.startingAt, 'yyyy-MM-dd') !== selectedDate) {
          shouldRemove = true
        }
      } else {
        shouldRemove = true
      }
    }
  } else if (deed.type === 'Project') {
    const selectedSkills = filters.get('selectedSkills')
    const allSkills = deed.allSkills()
    if (allSkills && selectedSkills.size > 0 && new Set(deed.allSkills()).intersect(selectedSkills).size === 0) {
      shouldRemove = true
    }
  }

  const selectedAttendanceOption = filters.get('selectedLocation')
  // only filter using an attendance option if one option is selected
  if (selectedAttendanceOption.size === 1) {
    if (selectedAttendanceOption.has('Virtual') && !deed.virtual) {
      shouldRemove = true
    }
    if (selectedAttendanceOption.has('In-person') && deed.virtual) {
      shouldRemove = true
    }
  }

  const selectedCauses = filters.get('selectedCauses')
  if (
    selectedCauses.size > 0 &&
    new Set(deed.categories).concat(deed.pillars, deed.ERGs, deed.SDGs).intersect(selectedCauses).size === 0
  ) {
    shouldRemove = true
  }

  // @NOTE-CH: For now, we only apply the location filter for these types of deeds as we don't want to mess up with Fundraisers and BaseEvents
  if (
    ['Event', 'Project'].includes(deed.type) &&
    deed.locationLatLng?.coordinates?.length &&
    location?.coordinates &&
    !deed.virtual
  ) {
    const radiusInMeters = (location.radius || 20) * 1.60934 * 1000 // Convert miles to meters as geolib only understands metric system
    const getGeoLibObject = ([lat, long]: Coordinates) => ({ latitude: lat, longitude: long })

    if (
      !isPointWithinRadius(
        // @NOTE-CH: The `deed.locationLatLng` is [Longitude,Latitude], so let's flip
        getGeoLibObject([deed.locationLatLng.coordinates[1], deed.locationLatLng.coordinates[0]]),
        // while the selected `location` is [Latitude,Longitude], so no flip
        getGeoLibObject(location.coordinates),
        radiusInMeters
      )
    ) {
      shouldRemove = true
    }
  }

  return !shouldRemove
}
