import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TFunction } from 'i18next'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  EligibilityFormStatus,
  GivingAccountWithdrawalStatus,
  VolunteerReward,
  VolunteerRewardApprovalStatus,
  VolunteerTimeApproval,
} from 'src/generated/graphql'
import { useHistory } from 'src/navigation'
import { Row, Touchable } from 'src/retired/elements'
import { Body2 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { convertDuration } from 'src/utils/convertDuration'

import { StatusChip, StatusChipStatuses } from '../StatusChip'

export const getVolunteerRewardStatusLabel = (volunteerReward: VolunteerReward, t: TFunction) => {
  if (volunteerReward.Donation?.GivingAccountWithdrawal?.status === GivingAccountWithdrawalStatus.Processed) {
    return t('volunteerTime', { context: StatusChipStatuses.PaidOut })
  }

  if (volunteerReward.VolunteerTime.approval === VolunteerTimeApproval.Rejected) {
    return t('volunteerTimeRejected', { context: 'npoRejected' })
  } else if (volunteerReward.approvalStatus === VolunteerRewardApprovalStatus.Rejected) {
    return t('volunteerTimeRejected', { context: 'companyRejected' })
  }

  if (volunteerReward.approvalStatus === VolunteerRewardApprovalStatus.Pending) {
    if (volunteerReward.formStatus === EligibilityFormStatus.NotEligible) {
      return t('notEligible')
    }
    if (volunteerReward.VolunteerTime.approval === VolunteerTimeApproval.Pending) {
      return t('volunteerTimePending', { context: 'npoApproval' })
    }
    return t('volunteerTimePending', { context: 'companyApproval' })
  } else {
    return t('volunteerTime', { context: _.upperFirst(volunteerReward.approvalStatus) })
  }
}

export const getVolunteerRewardStatus = (volunteerReward: VolunteerReward): StatusChipStatuses => {
  if (volunteerReward.Donation?.GivingAccountWithdrawal?.status === GivingAccountWithdrawalStatus.Processed) {
    return StatusChipStatuses.PaidOut
  }
  if (
    volunteerReward.VolunteerTime.approval === VolunteerTimeApproval.Rejected ||
    volunteerReward.approvalStatus === VolunteerRewardApprovalStatus.Rejected
  ) {
    return StatusChipStatuses.Rejected
  }
  if (volunteerReward.formStatus === EligibilityFormStatus.NotEligible) {
    return StatusChipStatuses.NotEligible
  }
  return _.upperFirst(volunteerReward.approvalStatus) as StatusChipStatuses
}

export const VolunteerRewardsTable = ({ volunteerRewards }: { volunteerRewards: VolunteerReward[] }) => {
  const { t } = useTranslation('volunteerTimesProfile')
  const history = useHistory()
  const { colors, metrics } = useDeedTheme()

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 0, borderRadius: 0 }}>
      <TableContainer
        sx={{
          maxHeight: 440,
          '& .MuiTableCell-root': {
            padding: { sm: '8px', md: '16px' },
          },
          '& .MuiTableRow-root > th.MuiTableCell-root:first-of-type': {
            paddingLeft: { sm: '16px', md: '24px' },
          },
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="volunteer rewards table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Body2 weight="500">{t`opportunities`}</Body2>
              </TableCell>
              <TableCell>
                <Body2 weight="500" center>{t`applicationDate`}</Body2>
              </TableCell>
              <TableCell>
                <Body2 weight="500" center>{t`hours`}</Body2>
              </TableCell>
              <TableCell>
                <Body2 weight="500" center>{t`grantStatus`}</Body2>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {volunteerRewards.map((volunteerReward) => (
              <TableRow key={volunteerReward.id}>
                <TableCell component="th" scope="row">
                  <Touchable
                    onPress={() => history.push(`/profile/volunteer-hours/${volunteerReward.VolunteerTime?.id}`)}
                    key={volunteerReward.id}
                  >
                    <Row style={{ alignItems: 'center', flexWrap: 'wrap' }}>
                      <Body2
                        style={{
                          maxWidth: metrics.isSmall ? 150 : 200,
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          textDecorationLine: 'underline',
                        }}
                      >
                        {volunteerReward.Deed?.name ||
                          t('externalVolunteeringForNonprofit', {
                            nonprofitName:
                              volunteerReward.Nonprofit?.name || volunteerReward.VolunteerTime?.externalNonprofitName,
                          })}
                      </Body2>
                    </Row>
                  </Touchable>
                </TableCell>
                <TableCell>
                  <Body2 center>
                    {volunteerReward.formSubmittedAt
                      ? t('date:dayMonthYearShort', { date: new Date(volunteerReward.formSubmittedAt) })
                      : t('notAvailable')}
                  </Body2>
                </TableCell>
                <TableCell>
                  <Body2 center>{convertDuration(Math.floor(volunteerReward.volunteerDuration / 60) * 60)}</Body2>
                </TableCell>
                <TableCell>
                  <StatusChip
                    label={getVolunteerRewardStatusLabel(volunteerReward, t)}
                    status={getVolunteerRewardStatus(volunteerReward)}
                    colors={colors}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
